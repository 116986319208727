import styled from 'styled-components';

export default styled.div`
  min-height: 400px;
  max-width: 1400px;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  margin-bottom: 60px;
  padding-left: 5%;
  padding-right: 5%;
  background-color: rgb(123, 140, 149);
  color: white;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
`;
