import styled from 'styled-components';

export default styled.button`
  font-size: 22px;
  font-weight: 700;
  padding: 12px 20px;
  color: #fff;
  background-color: #047bc1;
  border: 1px solid #047bc1;
  border-radius: 4px;
  margin-left: 5px;
  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed !Important;
  }
`;

export const SubmitButtonSmall = styled.button`
  background-color: #369af9;
  border 0;
  color: white;
  font-family: 'Source Sans Pro';
  font-weight: bold;
  padding: 5px 20px;
  border-radius: 12px;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed !Important;
  }
  font-size: 1em;
`;
