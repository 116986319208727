import React, { useState } from 'react';
import useGlobal from '../store';
import Page from '../components/Page';
import FormBanner from '../components/styles/FormBanner';
import Container from '../components/styles/Container';
import Heading from '../components/styles/Heading';
import Box from '../components/styles/Box';
import Input from '../components/styles/LargeInput';
import SubmitButton from '../components/styles/SubmitButton';

const ResetPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [, actions] = useGlobal();
  const { resetPasswordRequest } = actions;

  const reset = async () => {
    await resetPasswordRequest(email);
  };

  return (
    <Page>
      <FormBanner>
        <Container>
          <Box py="50px">
            <Heading my={3} fontSize={6} mx="auto">
              Reset Password
            </Heading>
            <Input
              id="username"
              onChange={e => setEmail(e.target.value)}
              placeholder="Enter email"
              value={email}
              type="text"
              color="black"
              width="250px"
            />
            <Box my={6}>
              <SubmitButton disabled={!email} onClick={reset}>
                Submit
              </SubmitButton>
            </Box>
          </Box>
        </Container>
      </FormBanner>
    </Page>
  );
};

export default ResetPasswordPage;
