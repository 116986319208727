import styled from 'styled-components';

const LargeInput = styled.input`
  background-color: white;
  color: gray;
  margin-right: 20px;
  border-radius: 5px;
  font-size: 16px;
  height: 48px;
  padding-left: 20px;
`;
export const EmailInput = styled(LargeInput)`
  width: 250px;
  color: black;
  margin-bottom: 15px;
`;
export default LargeInput;
